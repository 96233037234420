
@import '../theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: #{$ldirection};
  color: $color-black;
  padding: 1em 0;
  border-bottom: 0;
  .cart-item:hover {
    color: $color-black;
  }

  // Layout:
  .cart-item__img,
  .cart-item__info,
  .cart-item__cart-details,
  .cart-item__qty,
  .cart-item__price {
    float: #{$ldirection};
    display: block;
  }
  .cart-item__img {
    width: 25%;
    margin-#{$rdirection}: 2%;
  }
  .cart-item__info {
    width: 35%;
    margin-#{$rdirection}: 2%;
  }
  .cart-item__info__header {
    font-size: 1.2em;
  }
  .cart-item__cart-details {
    width: 36%;
    text-align: #{$rdirection};
  }

  // Styles:
  .cart-item__subheading {
    font-size: 1em;
    //@extend %font-helvetica-neue-medium;
    text-transform: uppercase;
  }
  .cart-item__sku-label {
    @include tracking(40);
  }
  .cart-item__title {
  }
  .cart-item__qty {
    text-transform: uppercase;
  }
  .cart-item__qty {
    text-align: #{$ldirection};
  }
  .cart-item__price {
    font-size: 1.45em;
    color: $color-black;
    @include tracking(45);
    //@extend %font-helvetica-neue-medium;
  }
  .cart-item__qty,
  .cart-item__price {
    float: #{$ldirection};
    width: 50%;
  }
  &__engraving-button {
    font-weight: normal;
    margin-top: 10px;
    padding: 5px;
    &-wrapper {
      clear: both;
    }
  }
}
